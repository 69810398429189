import React from 'react';
import H1 from "../../../UI/H1";
import SectionWrapper from "../../../UI/SectionWrapper";
import {makeStyles} from "@material-ui/core/styles";
import {themes} from "../../../../theme/themeContext/themes";
import {useTheme} from "../../../../theme/themeContext";
import UnderLine from "../../../UI/LineUnderTitle";
import {Box} from "@material-ui/core";
import WSGLogo from "../../../../assets/Partners/wsg.svg";
import BWLogo from "../../../../assets/Partners/bw.svg";
import TurLogo from "../../../../assets/Partners/tur-logo.svg";
import PisanieLogo from "../../../../assets/Partners/pisanie-logo.svg";
import SkumoLogo from "../../../../assets/Partners/skumo-logo.svg";
import EduLogo from "../../../../assets/Partners/edutraveler-logo.svg";
import FundLogo from "../../../../assets/Partners/fund-logo.svg";
import AdbisLogo from "../../../../assets/Partners/adbis-logo-index.svg";
import Slider from "../../../UI/Slider";

const useStyles = makeStyles((theme) => ({
    imgWrapper: {
        display: 'flex',
        width: '100%',
        height: '100%',

        //flexWrap: 'wrap',
        //gap: '32px',
        justifyContent: 'center',
        alignItems: 'center',
        // [theme.breakpoints.down('md')]: {
        //     flexWrap: 'wrap',
        // }
    },
    partnerImage: {
        [theme.breakpoints.down('sm')]: {
            //height: '80px',
        }
    },
}));

const Logos = [
    {
        id: 1,
        src: TurLogo,
        alt: 'TurLogo',
    },
    {
        id: 2,
        src: PisanieLogo,
        alt: 'PisanieLogo',
    },
    {
        id: 3,
        src: SkumoLogo,
        alt: 'SkumoLogo',
    },
    {
        id: 4,
        src: BWLogo,
        alt: 'BWLogo',
    },
    {
        id: 5,
        src: WSGLogo,
        alt: 'WSGLogo',
    },
    {
        id: 6,
        src: EduLogo,
        alt: 'EduLogo',
    },
    {
        id: 7,
        src: FundLogo,
        alt: 'FundLogo',
    },
    {
        id: 8,
        src: AdbisLogo,
        alt: 'AdbisLogo',
    }
];

const PartnersBlock = () => {
    const {theme} = useTheme();
    const classes = useStyles(themes[theme]);

    return (
        <SectionWrapper id="main-partners-block">
            <H1 text="Współpracujemy"/>
            <UnderLine/>
            <Slider
                projects={Logos}
                slideComponent={({project}) => (
                    <Box className={classes.imgWrapper}>
                        <img
                            key={project.id}
                            src={project.src}
                            alt={project.alt}
                            className={classes.partnerImage}
                        />
                    </Box>
                )}
            />
        </SectionWrapper>
    );
};

export default PartnersBlock;


