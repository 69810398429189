import React, {useEffect} from 'react';
import SectionWrapper from "../../UI/SectionWrapper";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Icon} from "@material-ui/core";
import BreadCrumbs from "../../UI/BreadCrumbs";
import Typography from "@material-ui/core/Typography";
import {useTheme} from "../../../theme/themeContext";
import {themes} from "../../../theme/themeContext/themes";
import {ReactComponent as FaceBookLogo} from "../../../assets/Icons/facebook-logo.svg";
import {ReactComponent as InstagramLogo} from "../../../assets/Icons/inst-logo.svg";
import {ReactComponent as LinkedinLogo} from "../../../assets/Icons/linkedin-logo.svg";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: 'Helvetica-Bold',
        fontSize: '56px',
        fontWeight: 700,
        color: ({textColor}) => textColor,
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            fontWeight: 700,
        },
    },
    titleText: {
        fontFamily: 'Helvetica-Bold',
        fontSize: '20px',
        fontWeight: '700',
        color: ({textColor}) => textColor,
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        }
    },
    descriptionText: {
        fontFamily: 'Helvetica-Regular',
        fontSize: '20px',
        fontWeight: '400',
        color: ({textColor}) => textColor,
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        }
    },
    boxWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        [theme.breakpoints.down('sm')]: {
            gap: '16px',
        }
    },
    descriptionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '40px',
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    link: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        textDecoration: 'none',
        "& path": {
            transition: '300ms ease-in-out',
            fill: ({iconColorFill}) => iconColorFill,
        },
        '&:hover': {
            "& path": {
                transition: '300ms ease-in-out',
                fill: ({iconColorFillHover}) => iconColorFillHover,
            },
        },
    },
}));


const ContactPage = () => {
    const {theme} = useTheme();
    const classes = useStyles(themes[theme]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <SectionWrapper paddingBottom="100px" paddingTop="20px">
            <BreadCrumbs/>
            <Box className={classes.textContainer}>
                <Box className={classes.descriptionContainer}>
                    <Typography variant="h1" className={classes.title}>
                        Kontakt
                    </Typography>
                    <Box className={classes.boxWrapper}>
                        <Box>
                            <Typography className={classes.titleText} style={{marginBottom: '10px'}}>Fundacja Krzewienia Kultury i Turystyki „Nad
                                Rzeką”</Typography>
                            <Typography className={classes.descriptionText}>Bartosza Głowackiego 41 / 32,</Typography>
                            <Typography className={classes.descriptionText}>85-717 Bydgoszcz, Polska</Typography>
                        </Box>
                        <Box>
                            <Typography className={classes.descriptionText}>NIP: 5542986945</Typography>
                            <Typography className={classes.descriptionText}>KRS: 0000852796</Typography>
                            <Typography className={classes.descriptionText}>REGON: 386662360</Typography>
                        </Box>
                        <Box>
                            <Typography className={classes.titleText}>E-mail:
                                <Typography className={classes.descriptionText}>
                                    <a href="mailto:fundacja.nadrzeka@gmail.com"
                                       style={{color: "inherit", textDecoration: 'none'}}>
                                        fundacja.nadrzeka@gmail.com
                                    </a>
                                </Typography>

                            </Typography>
                        </Box>
                        <Box>
                            <Box className={classes.textWrapper}>
                                <Typography className={classes.titleText}>Tel:</Typography>
                                <Typography className={classes.descriptionText} style={{marginBottom: '10px'}}>
                                    <a href="tel:+48884002977" style={{color: "inherit", textDecoration: 'none'}}>
                                        +48 884 002 977
                                    </a>
                                </Typography>
                                <Typography className={classes.descriptionText}>
                                    <a href="tel:+48727937366" style={{color: "inherit", textDecoration: 'none'}}>
                                        +48 727 937 366
                                    </a>
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.buttonWrapper}>
                            <Link to={'https://www.facebook.com/FundacjaNadRzeka/'} className={classes.link}>
                                <Icon
                                    component={FaceBookLogo}
                                    className={classes.icon}
                                    src={FaceBookLogo}
                                />
                                <Typography className={classes.descriptionText}>@FundacjaNadRzeka</Typography>
                            </Link>
                            <Link
                                to={'https://www.linkedin.com/company/fundacja-krzewienia-kultury-i-turystyki-nad-rzek%C4%85/'}
                                className={classes.link}>
                                <Icon
                                    component={LinkedinLogo}
                                    className={classes.icon}
                                    src={LinkedinLogo}
                                />
                                <Typography
                                    className={classes.descriptionText}>fundacja-krzewienia-kultury-i-turystyki-nad-rzeką</Typography>
                            </Link>

                            <Link to={'https://www.instagram.com/fundacja.nad_rzeka?igshid=YmMyMTA2M2Y%3D'}
                                  className={classes.link}>
                                <Icon
                                    component={InstagramLogo}
                                    className={classes.icon}
                                    src={InstagramLogo}
                                />
                                <Typography className={classes.descriptionText}>
                                    @fundacja.nad_rzeka
                                </Typography>
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </SectionWrapper>
    );
};

export default ContactPage;