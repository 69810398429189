import React, { useState, useEffect } from 'react';
import MainBannerComponent from "./MainBanner";
import ActualPostsBlock from "./ActualPosts";
import ActualProjectsBlock from "./Projects";
import AboutBlock from "./MainAbout";
import PartnersBlock from "./Partners";
import ContactBlock from "./Contact";
import InvestBlock from "./FinancePartners";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const MainPage = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            await new Promise((resolve) => setTimeout(resolve, 2000));
            setLoading(false);
        };

        fetchData().then(r => console.log('MainPage data loaded'));
    }, []); // Пустой массив зависимостей означает, что эффект будет вызван только при монтировании компонента

    return (
        <div>
            <MainBannerComponent />
            <ActualPostsBlock />
            <ActualProjectsBlock />
            <AboutBlock />
            <PartnersBlock />
            <ContactBlock />
            <InvestBlock />
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default MainPage;

