import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Typography, Box, CircularProgress, Backdrop} from '@material-ui/core';
import SectionWrapper from '../../UI/SectionWrapper';
import {useTheme} from '../../../theme/themeContext';
import {themes} from '../../../theme/themeContext/themes';
import BreadCrumbs from '../../UI/BreadCrumbs';
import FullscreenModal from "../../UI/ImageModalComponent";
import PostsSlider from "../../UI/PostsSlider";
import PostCard from "../../UI/ActualPostCard/PostCard";
import UnderLine from "../../UI/LineUnderTitle";
import H1 from "../../UI/H1";
import DownloadButton from "../../UI/DownloadButton";
import {fetchPostBySlug, fetchAllPosts} from './apiRequests';
import useStyles from './styles';
import Button from "@material-ui/core/Button";

const PostDetails = () => {
    const {theme} = useTheme();
    const classes = useStyles(themes[theme]);
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [mediaData, setMediaData] = useState({});
    const [fullscreenModalOpen, setFullscreenModalOpen] = useState(false);
    const [allPosts, setAllPosts] = useState([]);

    const handleAllPostsLoaded = (posts) => {
        setAllPosts(posts);
    };

    const {postSlug} = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                const dataPost = await fetchPostBySlug(postSlug);
                setPost(dataPost);
            } catch (error) {
                // Обработка ошибок
            } finally {
                setLoading(false);
            }
        };

        const fetchAllPostsData = async () => {
            try {
                const allPostsData = await fetchAllPosts();
                handleAllPostsLoaded(allPostsData);

                const mediaIds = allPostsData.map((post) => post.featured_media);
                const mediaPromises = mediaIds.map(async (mediaId) => {
                    try {
                        const mediaData = await fetch(`https://fundacjanadrzeka.com/api/wp-json/wp/v2/media/${mediaId}`)
                            .then(response => response.json());

                        if (mediaData && mediaData.id) {
                            return mediaData;
                        } else {
                            console.error('Invalid media data:', mediaData);
                            return null;
                        }
                    } catch (error) {
                        console.error('Error fetching media data:', error);
                        return null;
                    }
                });

                const mediaResults = await Promise.all(mediaPromises);

                const mediaDataObject = mediaResults.reduce((acc, media) => {
                    if (media) {
                        acc[media.id] = media;
                    }
                    return acc;
                }, {});
                setMediaData(mediaDataObject);
            } catch (error) {
                console.error('Error fetching all posts data:', error);
            }
        };

        setSelectedImageIndex(0);

        fetchData();
        fetchAllPostsData();
    }, [postSlug]);


    const extractImageUrls = (content) => {
        const imageUrls = [];
        const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;

        const regex = /<img[^>]+src="([^">]+)"[^>]*>/g;
        let match;
        while ((match = regex.exec(content)) !== null) {
            const imageUrl = match[1];

            if (!imageUrl.match(emojiRegex)) {
                imageUrls.push(imageUrl);
            }
        }
        return imageUrls;
    };

    const imageUrls = extractImageUrls(post?.content?.rendered || '');

    const extractImages = (content) => {
        const images = [];
        const regex = /<img[^>]+src="([^">]+)"[^>]*>/g;
        let match;
        while ((match = regex.exec(content)) !== null) {
            const imgTag = match[0]; // полный тег img
            images.push(imgTag);
        }
        return images;
    };

    const images = extractImages(post?.content?.rendered || '');

    const extractFileUrls = (content) => {
        const fileUrlsSet = new Set();
        const regex = /<a[^>]+href="([^">]+)"[^>]*>(.*?)<\/a>/g;

        let match;
        while ((match = regex.exec(content)) !== null) {
            const url = match[1];

            const fileExtension = url.split('.').pop()?.toLowerCase();

            const isFileUrl =
                !url.startsWith('mailto:') &&
                ['pdf', 'doc', 'docx'].includes(fileExtension);

            if (isFileUrl && !fileUrlsSet.has(url)) {
                fileUrlsSet.add(url);
            }
        }

        return [...fileUrlsSet];
    };

    const filteredFileUrls = extractFileUrls(post?.content?.rendered || '');

    const descriptionWithoutImagesAndFiles = filteredFileUrls.reduce((content, fileUrl) => {
        // eslint-disable-next-line no-useless-escape
        const fileRegex = new RegExp(`<a[^>]+href="${fileUrl}"[^>]*>.*?<\/a>`, 'g');
        const imageRegex = /<img[^>]+src="([^">]+)"[^>]*>/g;

        const contentWithoutFiles = content.replace(fileRegex, '');

        const contentWithoutImages = contentWithoutFiles.replace(imageRegex, '');

        return contentWithoutImages;
    }, post?.content?.rendered || '');

    const handleOpenFullscreenModal = (index) => {
        setSelectedImageIndex(index);  // Используем переданный индекс
        setFullscreenModalOpen(true);
    };

    const handleOpenMainImageModal = () => {
        const mainImageUrl = post?._embedded?.['wp:featuredmedia']?.[0]?.source_url;

        if (mainImageUrl) {
            setSelectedImageIndex(null);
            setFullscreenModalOpen(true);
        } else {
            console.error('Main image URL not found');
        }
    };

    const handleCloseFullscreenModal = () => {
        setFullscreenModalOpen(false);
    };

    const handleNextImage = () => {
        setSelectedImageIndex((prev) => {
            if (prev === imageUrls.length - 1) {
                return 0;
            }

            return prev + 1;
        });
    }

    const handlePrevImage = () => {
        setSelectedImageIndex((prev) => {
            if (prev === 0) {
                return imageUrls.length - 1;
            }

            return prev - 1;
        });
    }

    //TODO: refactor this govnocode
    const renderImages = () => {
        const numImages = imageUrls.length;
        //approve
        if (numImages === 1) {
            return (
                <div style={{position: 'relative', height: '100%', width: '100%'}}>
                    {images.slice(0, 1).map((imgTag, index) => (
                        <div
                            key={index}
                            dangerouslySetInnerHTML={{__html: imgTag}}
                            className={classes.facebookStyleImageLarge + ' ' + classes.largeActiveViewForMobile}
                            onClick={() => handleOpenFullscreenModal(0)}
                        />
                    ))}
                    {/*<figure>*/}
                    {/*    <img*/}
                    {/*        src={imageUrls[0]}*/}
                    {/*        alt="AlbumImage 1"*/}
                    {/*        className={classes.facebookStyleImageLarge + ' ' + classes.largeActiveViewForMobile}*/}
                    {/*        onClick={() => handleOpenFullscreenModal(0)}*/}
                    {/*    />*/}
                    {/*</figure>*/}
                </div>
            );
            // approve
        } else if (numImages === 2) {
            return (
                <>
                    {/*{imageUrls.slice(0, 2).map((imageUrl, index) => (*/}
                    {/*    <div key={index} style={{position: 'relative', height: '100%', width: '100%'}}>*/}
                    {/*        <figure>*/}
                    {/*            <img*/}
                    {/*                src={imageUrl}*/}
                    {/*                alt={`AlbumImage ${index + 1}`}*/}
                    {/*                className={classes.facebookStyleImage}*/}
                    {/*                onClick={() => handleOpenFullscreenModal(index)}*/}
                    {/*            />*/}
                    {/*        </figure>*/}
                    {/*    </div>*/}
                    {/*))}*/}
                    {images.slice(0, 2).map((imgTag, index) => (
                        <div key={index} style={{position: 'relative', height: '100%', width: '100%'}}>
                            <div
                                key={index}
                                dangerouslySetInnerHTML={{__html: imgTag}}
                                className={classes.facebookStyleImage}
                                onClick={() => handleOpenFullscreenModal(index)}
                            />
                        </div>
                    ))}
                </>
            );
            //approve
        } else if (numImages === 3) {

            return (
                <>
                    <div className={classes.columnsWithTwoImagesAlternative + ' ' + classes.fullWidth}>
                        {/*{imageUrls.slice(0, 2).map((imageUrl, index) => (*/}
                        {/*    <div key={index} style={{position: 'relative', height: '100%'}}>*/}
                        {/*        <figure>*/}
                        {/*            <img*/}
                        {/*                src={imageUrl}*/}
                        {/*                alt={`AlbumImage ${index + 1}`}*/}
                        {/*                className={classes.facebookStyleImage + ' ' + classes.twoImagesBoxView}*/}
                        {/*                //style={{aspectRatio: '3/1'}}*/}
                        {/*                onClick={() => handleOpenFullscreenModal(index)}*/}
                        {/*            />*/}
                        {/*        </figure>*/}
                        {/*    </div>*/}
                        {/*))}*/}
                        {images.slice(0, 2).map((imgTag, index) => (
                            <div key={index} style={{position: 'relative', height: '100%'}}>
                                <div
                                    key={index}
                                    dangerouslySetInnerHTML={{__html: imgTag}}
                                    className={classes.facebookStyleImage + ' ' + classes.twoImagesBoxView}
                                    onClick={() => handleOpenFullscreenModal(index)}
                                />
                            </div>
                        ))}
                    </div>
                    <div className={classes.columnsWithOneImage}>
                        {images.slice(2, 3).map((imgTag, index) => (
                                <div
                                    key={index}
                                    dangerouslySetInnerHTML={{__html: imgTag}}
                                    className={classes.facebookStyleImageLarge}
                                    onClick={() => handleOpenFullscreenModal(index + 2)}
                                />
                        ))}
                        {/*{imageUrls.slice(2, 3).map((imageUrl, index) => (*/}
                        {/*    <figure>*/}
                        {/*        <img*/}
                        {/*            key={index + 2}*/}
                        {/*            src={imageUrl}*/}
                        {/*            alt={`AlbumImage ${index + 3}`}*/}
                        {/*            className={classes.facebookStyleImageLarge}*/}
                        {/*            onClick={() => handleOpenFullscreenModal(index + 2)}*/}
                        {/*        />*/}
                        {/*    </figure>*/}
                        {/*))}*/}
                    </div>
                </>
            );
            //approve
        } else if (numImages === 4) {

            return (
                <>
                    <div className={classes.columnsWithTwoImagesAlternative}>
                        {/*{imageUrls.slice(0, 2).map((imageUrl, index) => (*/}
                        {/*    <div key={index} style={{position: 'relative', height: '100%', width: '100%'}}>*/}
                        {/*        <figure>*/}
                        {/*            <img*/}
                        {/*                src={imageUrl}*/}
                        {/*                alt={`AlbumImage ${index + 1}`}*/}
                        {/*                className={classes.facebookStyleImage + ' ' + classes.facebookStyleImageAlternative}*/}
                        {/*                onClick={() => handleOpenFullscreenModal(index)}*/}
                        {/*            />*/}
                        {/*        </figure>*/}
                        {/*    </div>*/}
                        {/*))}*/}
                        {images.slice(0, 2).map((imgTag, index) => (
                            <div key={index} style={{position: 'relative', height: '100%', width: '100%'}}>
                                <div
                                    key={index}
                                    dangerouslySetInnerHTML={{__html: imgTag}}
                                    className={classes.facebookStyleImage + ' ' + classes.facebookStyleImageAlternative}
                                    onClick={() => handleOpenFullscreenModal(index)}
                                />
                            </div>
                        ))}
                    </div>
                    <div className={classes.columnsWithTwoImagesAlternative}>
                        {/*{imageUrls.slice(2, 4).map((imageUrl, index) => (*/}
                        {/*    <div key={index + 3} style={{position: 'relative', height: '100%', width: '100%'}}>*/}
                        {/*        <figure>*/}
                        {/*            <img*/}
                        {/*                src={imageUrl}*/}
                        {/*                alt={`AlbumImage ${index + 4}`}*/}
                        {/*                className={classes.facebookStyleImage + ' ' + classes.facebookStyleImageAlternative}*/}
                        {/*                onClick={() => handleOpenFullscreenModal(index + 2)}*/}
                        {/*            />*/}
                        {/*        </figure>*/}
                        {/*    </div>*/}
                        {/*))}*/}
                        {images.slice(2, 4).map((imgTag, index) => (
                            <div key={index} style={{position: 'relative', height: '100%', width: '100%'}}>
                                <div
                                    key={index}
                                    dangerouslySetInnerHTML={{__html: imgTag}}
                                    className={classes.facebookStyleImage + ' ' + classes.facebookStyleImageAlternative}
                                    onClick={() => handleOpenFullscreenModal(index + 2)}
                                />
                            </div>
                        ))}
                    </div>
                </>
            );
            //approve
        } else if (numImages >= 5) {

            return (
                <>
                    <div className={classes.columnsWithTwoImages}>
                        {/*{imageUrls.slice(0, 2).map((imageUrl, index) => (*/}
                        {/*    <div key={index} style={{position: 'relative', height: '100%'}}>*/}
                        {/*        <figure>*/}
                        {/*            <img*/}
                        {/*                src={imageUrl}*/}
                        {/*                alt={`AlbumImage ${index + 1}`}*/}
                        {/*                className={classes.facebookStyleImage}*/}
                        {/*                onClick={() => handleOpenFullscreenModal(index)}*/}
                        {/*            />*/}
                        {/*        </figure>*/}
                        {/*    </div>*/}
                        {/*))}*/}
                        {images.slice(0, 2).map((imgTag, index) => (
                            <div key={index} style={{position: 'relative', width: '100%', height: '100%'}}>
                                <div
                                    key={index}
                                    dangerouslySetInnerHTML={{__html: imgTag}}
                                    className={classes.facebookStyleImage}
                                    onClick={() => handleOpenFullscreenModal(index)}
                                />
                            </div>
                        ))}
                    </div>
                    <div className={classes.columnsWithOneImage}>
                        {/*{imageUrls.slice(2, 3).map((imageUrl, index) => (*/}
                        {/*    <figure>*/}
                        {/*        <img*/}
                        {/*            key={index + 2}*/}
                        {/*            src={imageUrl}*/}
                        {/*            alt={`AlbumImage ${index + 3}`}*/}
                        {/*            className={classes.facebookStyleImageLarge}*/}
                        {/*            onClick={() => handleOpenFullscreenModal(index + 2)}*/}
                        {/*        />*/}
                        {/*    </figure>*/}
                        {/*))}*/}
                        {images.slice(2, 3).map((imgTag, index) => (
                                <div
                                    key={index}
                                    dangerouslySetInnerHTML={{__html: imgTag}}
                                    className={classes.facebookStyleImageLarge}
                                    onClick={() => handleOpenFullscreenModal(index + 2)}
                                />
                        ))}
                    </div>
                    <div className={classes.columnsWithTwoImages}>
                        {/*{imageUrls.slice(3, 5).map((imageUrl, index) => (*/}
                        {/*    <div key={index + 3} style={{position: 'relative', height: '100%'}}>*/}
                        {/*        <figure>*/}
                        {/*            <img*/}
                        {/*                src={imageUrl}*/}
                        {/*                alt={`AlbumImage ${index + 4}`}*/}
                        {/*                className={classes.facebookStyleImage}*/}
                        {/*                onClick={() => handleOpenFullscreenModal(index + 3)}*/}
                        {/*            />*/}
                        {/*        </figure>*/}
                        {/*        {index === imageUrls.slice(3, 5).length - 1 && (*/}
                        {/*            <Button*/}
                        {/*                className={classes.viewAllButton}*/}
                        {/*                onClick={() => handleOpenFullscreenModal(0)}*/}
                        {/*            >*/}
                        {/*                kolejne {imageUrls.length}*/}
                        {/*            </Button>*/}
                        {/*        )}*/}
                        {/*    </div>*/}
                        {/*))}*/}
                        {images.slice(3, 5).map((imgTag, index) => (
                            <div key={index} style={{position: 'relative', width: '100%', height: '100%'}}>
                                <div
                                    key={index}
                                    dangerouslySetInnerHTML={{__html: imgTag}}
                                    className={classes.facebookStyleImage}
                                    onClick={() => handleOpenFullscreenModal(index + 3)}
                                />
                                {index === images.slice(3, 5).length - 1 && (
                                    <Button
                                        className={classes.viewAllButton}
                                        onClick={() => handleOpenFullscreenModal(0)}
                                    >
                                        kolejne {images.length}
                                    </Button>
                                )}
                            </div>
                        ))}
                    </div>
                </>
            );
        } else {
            // Логика для других случаев
            return null;
        }
    };

    return (
        <SectionWrapper paddingBottom="100px" paddingTop="20px">
            <BreadCrumbs/>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Box className={classes.contentWrapper}>
                <Box className={classes.textWrapper}>
                    <Typography variant="h1" className={classes.title}
                                dangerouslySetInnerHTML={{__html: post?.title?.rendered}}/>
                    <Typography variant="h1" className={classes.date}>
                        {new Date(post?.date).toLocaleDateString('pl-PL', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric'
                        })}
                    </Typography>
                    <Box>
                        {post?._embedded && post._embedded['wp:featuredmedia'] && (
                            <div>
                                <img
                                    src={post._embedded['wp:featuredmedia'][0].source_url}
                                    alt={post.title?.rendered}
                                    className={classes.imageMobile}
                                    loading="lazy"
                                    onClick={handleOpenMainImageModal}

                                />
                            </div>
                        )}
                    </Box>
                    <Box className={classes.textContainer}>
                        <Typography variant="body1" dangerouslySetInnerHTML={{__html: descriptionWithoutImagesAndFiles}}
                                    className={classes.description}/>
                    </Box>
                    <Box className={classes.downloadButtonWrapper}>
                        {filteredFileUrls.map((filteredFileUrl, index) => {
                            return <DownloadButton key={index} fileUrl={filteredFileUrl}/>;
                        })}
                    </Box>
                </Box>
                <Box className={classes.imageWrapper}>
                    {post?._embedded && post._embedded['wp:featuredmedia'] && (
                        <div className={classes.imageContainer}>
                            <img
                                src={post._embedded['wp:featuredmedia'][0].source_url}
                                alt={post.title?.rendered}
                                className={classes.image}
                                loading="lazy"
                                onClick={handleOpenMainImageModal}
                            />
                        </div>
                    )}

                </Box>
            </Box>

            <Box className={classes.root}>
                <Box className={classes.columnsWrapper}>
                    <Box className={classes.columnsWrapper}>{renderImages()}</Box>
                </Box>
                <FullscreenModal
                    open={fullscreenModalOpen}
                    onClose={handleCloseFullscreenModal}
                    imgTag={images[selectedImageIndex]}
                    index={selectedImageIndex}
                    imageSrc={
                        selectedImageIndex !== null
                            ? images[selectedImageIndex]
                            : post?._embedded?.['wp:featuredmedia']?.[0]?.source_url || ''
                    }
                    onNext={handleNextImage}
                    onPrev={handlePrevImage}
                />
            </Box>
            <Box>
                <H1 text="Pozostałe"/>
                <UnderLine/>
                <PostsSlider
                    projects={allPosts.filter((p) => p.id !== post?.id)}
                    slideComponent={({project}) => (
                        <PostCard post={project} mediaData={mediaData}/>
                    )}
                />
            </Box>
        </SectionWrapper>
    );
};

export default PostDetails;
