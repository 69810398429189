import React from 'react';
import H1 from "../../../UI/H1";
import SectionWrapper from "../../../UI/SectionWrapper";
import {makeStyles} from "@material-ui/core/styles";
import StyledButton from "../../../UI/StyledButton";
import {Box, Grid} from "@material-ui/core";
import {themes} from "../../../../theme/themeContext/themes";
import {useTheme} from "../../../../theme/themeContext";
import UnderLine from "../../../UI/LineUnderTitle";
import BannerImage from "../../../../assets/Banners/about-banner-test.jpg";

const useStyles = makeStyles((theme) => ({
    contentWrapper: {
        alignItems: 'center',
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
    },
    textAbout: {
        fontFamily: 'Helvetica-Regular',
        fontSize: '20px',
        fontWeight: '400',
        marginBottom: '32px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '20px',
            fontSize: '16px',
            marginTop: '0',
        }
    }
}));

const aboutText = "Grupą trzech znajomych, którzy dostrzegli potrzebę wprowadzenia na rynek turystyczny alternatywnych i nowoczesnych form działań wizerunkowych i promocyjnych. Nasz międzynarodowy kolektyw utworzyli w 2019 r.:Piotr Weckwerth, Serhii Zinchenko oraz Anton Karabach.\n" +
    "Główną siedzibą Fundacji jest Bydgoszcz, ale zasięg jej działań sięga znacznie dalej, obejmując nie tylko obszar miasta czy województwa kujawsko-pomorskiego, ale także całej Polski a nawet Europy. Szczególnie ważnym puntem działań Organizacji jest Ukraina.";

const AboutBlock = () => {
    const {theme} = useTheme();
    const classes = useStyles(themes[theme]);

    return (
        <SectionWrapper id="main-about-block">
            <H1 text="O FUNDACJI"/>
            <UnderLine/>
            <Grid container spacing={3} className={classes.contentWrapper}>
                <Grid item xs={12} sm={6} md={6}>
                            <img src={BannerImage} alt="about image" className={classes.image}/>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                            <p className={classes.textAbout}>{aboutText}</p>
                            <Box>
                                <StyledButton text="Zobacz więcej" to="/o-fundacji" width="100%"/>
                            </Box>
                </Grid>
            </Grid>
        </SectionWrapper>
    );
};

export default AboutBlock;

