import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {makeStyles} from '@material-ui/core/styles';
import Logo from "../../../assets/Logo/LOGO.svg";
import {Box, Icon} from "@material-ui/core";
import {Link} from "react-router-dom";
import {useTheme} from "../../../theme/themeContext";
import {themes} from "../../../theme/themeContext/themes";
import {ReactComponent as WCAGIcon} from "../../../assets/Icons/wcag-logo.svg";
import {ReactComponent as FaceBookLogo} from "../../../assets/Icons/facebook-logo.svg";
import {ReactComponent as InstagramLogo} from "../../../assets/Icons/inst-logo.svg";
import {ReactComponent as LinkedinLogo} from "../../../assets/Icons/linkedin-logo.svg";
import SettingsDrawer from "../../UI/WCAGDrawer";
import ToggleMenu from "../ToggleMenu";


const navLinksData = [
    {url: '/o-fundacji', text: 'O fundacji'},
    {url: '/projekty', text: 'Projekty'},
    {url: '/aktualnosci', text: 'Aktualności'},
    {url: 'https://turystykabezfiltrow.com/', text: 'Blog', openInNewTab: true},
    {url: 'https://turystykabezfiltrow.com/wycieczki/', text: 'Wycieczki', openInNewTab: true},
    {url: '/kontakt', text: 'Kontakt'},
];


const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: ({backgroundColor}) => backgroundColor,
        boxShadow: 'none',
        transition: "all 0.3s ease-out",
    },
    // hidden: {
    //     transform: "translate(0, -100%)",
    // },
    menuButton: {
        marginRight: 10,
    },
    toolbar: {
        padding: '20px',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 20px',
            display: 'flex',
            justifyContent: 'space-between',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '20px'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '20px 40px'
        }
    },
    title: {
        flexGrow: 1,
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    buttonWrapper: {
        display: 'flex',
        //alignItems: 'center',
        flexDirection: 'row',
        gap: '20px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    navButton: {
        color: ({postsTextColor}) => postsTextColor,
        transition: "all 0.3s ease-out",
        textDecoration: 'none',
        fontSize: '20px',
        fontFamily: 'Helvetica-Regular',
        textTransform: 'none',
        fontWeight: 400,
        padding: '10px',
        '&:hover': {
            backgroundColor: 'transparent',
            color: ({postsHoverTextColor}) => postsHoverTextColor,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    wcagButton: {
        border: 'none',
        background: WCAGIcon,
        backgroundColor: 'transparent',
        cursor: 'pointer',
        "& path": {
            transition: '300ms ease-in-out',
            fill: ({iconColorFill}) => iconColorFill,
        },
        '&:hover': {
            "& path": {
                transition: '300ms ease-in-out',
                fill: ({iconColorFillHover}) => iconColorFillHover,
            },
        },
    },
    link: {
        textDecoration: 'none',
        "& path": {
            transition: '300ms ease-in-out',
            fill: ({iconColorFill}) => iconColorFill,
        },
        '&:hover': {
            "& path": {
                transition: '300ms ease-in-out',
                fill: ({iconColorFillHover}) => iconColorFillHover,
            },
        },
    },
    mobileButtonSection: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'row',
            gap: '32px',
        }
    }
}));

function Header() {
    // eslint-disable-next-line no-unused-vars
    const {theme, toggleTheme} = useTheme();
    const classes = useStyles(themes[theme]);

    return (
        <AppBar position={'static'} className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <a href={'/'}>
                    <img src={Logo} alt="logo" className={classes.logo}/>
                </a>
                <Box className={classes.title}>
                    {navLinksData.map((link) => (
                        <Link
                            key={link.url}
                            to={link.url}
                            className={classes.navButton}
                            {...(link.openInNewTab && {
                                target: '_blank',
                                rel: 'noopener noreferrer',
                            })}
                        >
                            {link.text}
                        </Link>
                    ))}
                </Box>

                <Box className={classes.buttonWrapper}>
                    <Box>
                        <SettingsDrawer/>
                    </Box>
                    <Link to={'https://www.facebook.com/FundacjaNadRzeka/'} className={classes.link}>
                        <Icon
                            component={FaceBookLogo}
                            className={classes.icon}
                            src={FaceBookLogo}
                        />
                    </Link>
                    <Link
                        to={'https://www.linkedin.com/company/fundacja-krzewienia-kultury-i-turystyki-nad-rzek%C4%85/'}
                        className={classes.link}>
                        <Icon
                            component={LinkedinLogo}
                            className={classes.icon}
                            src={LinkedinLogo}
                        />
                    </Link>
                    <Link to={'https://www.instagram.com/fundacja.nad_rzeka?igshid=YmMyMTA2M2Y%3D'}
                          className={classes.link}>
                        <Icon
                            component={InstagramLogo}
                            className={classes.icon}
                            src={InstagramLogo}
                        />
                    </Link>
                    {/*<StyledButton variant="dark" text="Pezekaż darowiznę" to="/donate"/>*/}
                </Box>
                {/*<FloatingButton />*/}
                <Box className={classes.mobileButtonSection}>
                    <SettingsDrawer/>
                    <ToggleMenu/>
                </Box>

            </Toolbar>
        </AppBar>
    );
}

export default Header;