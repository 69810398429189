import React from 'react';
import H1 from "../../../UI/H1";
import SectionWrapper from "../../../UI/SectionWrapper";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Grid} from "@material-ui/core";
import {themes} from "../../../../theme/themeContext/themes";
import {useTheme} from "../../../../theme/themeContext";
import UnderLine from "../../../UI/LineUnderTitle";
import BannerImage from "../../../../assets/Photos/DSC07411-900x507.jpg";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    contentWrapper: {
        alignItems: 'center',
        //paddingBottom: '100px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            alignItems: 'unset',
        }
    },
    bannerImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
    },
    titleText: {
        fontFamily: 'Helvetica-Bold',
        fontSize: '20px',
        fontWeight: '700',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        }
    },
    descriptionText: {
        fontFamily: 'Helvetica-Regular',
        fontSize: '20px',
        fontWeight: '400',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        }
    },
    boxWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        [theme.breakpoints.down('sm')]: {
            gap: '16px',
        }
    },
}));


const ContactBlock = () => {
    const {theme} = useTheme();
    const classes = useStyles(themes[theme]);
// eslint-disable-next-line no-unused-vars
    return (
        <SectionWrapper id="main-contact">
            <H1 text="Kontakt"/>
            <UnderLine/>
            <Grid container spacing={3} className={classes.contentWrapper}>
                <Grid item xs={12} sm={6} md={6}>
                    <Box className={classes.boxWrapper}>
                        <Box>
                            <Typography className={classes.titleText}>Fundacja Krzewienia Kultury i Turystyki „Nad Rzeką”</Typography>
                            <Typography className={classes.descriptionText}>Bartosza Głowackiego 41 / 32,</Typography>
                            <Typography className={classes.descriptionText}>85-717 Bydgoszcz, Polska</Typography>
                        </Box>
                        <Box>
                            <Typography className={classes.descriptionText}>NIP: 5542986945</Typography>
                            <Typography className={classes.descriptionText}>KRS: 0000852796</Typography>
                            <Typography className={classes.descriptionText}>REGON: 386662360</Typography>
                        </Box>
                        <Box>
                            <Box>
                                <Typography className={classes.titleText}>Tel:</Typography>
                                <Typography className={classes.descriptionText}>
                                    <a href="tel:+48884002977" style={{color: "inherit", textDecoration: 'none'}}>
                                        +48 884 002 977
                                    </a>
                                </Typography>
                                <Typography className={classes.descriptionText}>
                                    <a href="tel:+48727937366" style={{color: "inherit", textDecoration: 'none'}}>
                                        +48 727 937 366
                                    </a>
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Typography className={classes.titleText}>E-mail:</Typography>
                            <Typography className={classes.descriptionText}>
                                <a href="mailto:fundacja.nadrzeka@gmail.com"
                                   style={{color: "inherit", textDecoration: 'none'}}>
                                    fundacja.nadrzeka@gmail.com
                                </a>
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <img src={BannerImage} alt="Banner" className={classes.bannerImage}/>
                </Grid>
            </Grid>
        </SectionWrapper>
    );
};

export default ContactBlock;