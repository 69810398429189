import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Box, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../../theme/default";
import H4 from "../H4";
import {useTheme} from "../../../theme/themeContext";
import {themes} from "../../../theme/themeContext/themes";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        cursor: 'pointer',
        transition: "all 0.5s ease-out",
        '&:hover': {
            transform: 'scale(1.02)',
            transition: "all 0.5s ease-out",
            '& $imageLarge': {
                transition: "all 0.5s ease-out",
                transform: 'scale(1.05)',
            }
        },
        '&:hover $h4': {
            transition: "all 0.3s ease-out",
            color: ({postsHoverTextColor}) => postsHoverTextColor,
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '8px',
        }
    },
    rootSmall: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        transition: "all 0.5s ease-out",
        //marginBottom: '20px',
        '&:hover': {
            transform: 'scale(1.02)',
            transition: "all 0.5s ease-out",
            '& $imageSmall': {
                transition: "all 0.5s ease-out",
                transform: 'scale(1.05)',
            }
        },
        '&:hover $h4': {
            transition: "all 0.3s ease-out",
            color: ({postsHoverTextColor}) => postsHoverTextColor,
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0',
        }
    },
    textContainer: {
        flex: 1,
        padding: '10px',
        border: '1px solid black',
    },
    image: {
        width: '100%',
        height: '200px',
        objectFit: 'cover',
        objectPosition: 'center',
    },
    imageContainer: {
        display: 'flex',
        flex: '0 0 auto',
        overflow: 'hidden',
    },
    date: {
        fontFamily: 'Helvetica-Regular',
        fontSize: '20px',
        fontWeight: '400',
        opacity: '0.6',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        }
    },
    line: {
        width: '70%',
        backgroundColor: ({lineBackgroundColor}) => lineBackgroundColor,
        opacity: '0.6',
        marginBottom: '12px',
        height: '1px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    textContainerSmall: {
        flex: 1,
        padding: '10px',
        //border: '1px solid black',
    },
    textContainerLarge: {
        flex: 1,
        padding: '10px',
        // Убрать границу у больших карточек
    },
    imageSmall: {
        width: '150px',
        height: '200px',
        objectFit: 'cover',
        objectPosition: 'center',
        transition: "all 0.5s ease-out",
        [theme.breakpoints.down('sm')]: {
            width: '100px',
            height: '132px',
        },
        [theme.breakpoints.up('md')]: {
            width: '150px',
            height: '200px',
        }

    },
    imageLarge: {
        width: '100%',
        height: '500px',
        objectFit: 'cover',
        objectPosition: 'center',
        transition: "all 0.5s ease-out",

        [theme.breakpoints.down('sm')]: {
            width: '100px',
            height: '132px',
        },
        [theme.breakpoints.up('md')]: {
            width: '100%',
            height: '600px',
        }
    },
    // gridContainer: {
    //     [theme.breakpoints.down('sm')]: {
    //         display: 'flex',
    //         flexDirection: 'column',
    //         gap: '20px',
    //     }
    // },
    linkWrapper: {
        textDecoration: 'none',
        color: 'inherit', // Унаследовать цвет из родительского элемента
    },
    boxWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '20px',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
            flexDirection: 'column',
            gap: '20px',
        }
    },
    smallCardBoxWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    largeCardBoxWrapper: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: '10px',
        }
    },
}));

const ProjectCard = ({project, projectMediaData}) => {
    const {theme} = useTheme();
    const classes = useStyles(themes[theme]);
    const featuredMediaId = project.featured_media;
    const media = featuredMediaId ? projectMediaData[featuredMediaId] : null;

    return (
        <Link to={`/projekty/${project.slug}`} className={classes.linkWrapper}>
            <Box className={project.large ? classes.root : classes.rootSmall}>
                {media && (
                    <Box className={classes.imageContainer}>
                        <img
                            src={media.source_url}
                            alt={project.title?.rendered}
                            className={project.large ? classes.imageLarge : classes.imageSmall}
                        />
                    </Box>
                )}
                <Box className={project.large ? classes.textContainerLarge : classes.textContainerSmall}>
                    <H4 className={classes.h4}>{project.title?.rendered}</H4>
                    <div className={classes.line}/>
                    <Typography variant="h1" className={classes.date}>
                        {new Date(project?.date).toLocaleDateString('pl-PL', { month: 'long', day: 'numeric', year: 'numeric' })}
                    </Typography>
                </Box>
            </Box>
        </Link>
    );
};

const Projects = ({projectLimit}) => {
    const {theme} = useTheme();
    const classes = useStyles(themes[theme]);
    const [projectData, setProjectData] = useState([]);
    const [projectMediaData, setProjectMediaData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const categoryResponse = await axios.get(`https://fundacjanadrzeka.com/api/wp-json/wp/v2/categories?slug=project`);
                const categoryId = categoryResponse.data[0]?.id;

                if (!categoryId) {
                    console.error('Category not found');
                    return;
                }

                const response = await axios.get(`https://fundacjanadrzeka.com/api/wp-json/wp/v2/posts?categories=${categoryId}&per_page=${projectLimit}`);
                setProjectData(response.data);

                const mediaIds = response.data.map((project) => project.featured_media);
                const mediaPromises = mediaIds.map((mediaId) =>
                    axios.get(`https://fundacjanadrzeka.com/api/wp-json/wp/v2/media/${mediaId}`)
                );
                const mediaResults = await Promise.all(mediaPromises);

                const mediaDataObject = mediaResults.reduce((acc, media) => {
                    acc[media.data.id] = media.data;
                    return acc;
                }, {});
                setProjectMediaData(mediaDataObject);
            } catch (error) {
                console.error('Error fetching project data:', error);
            }
        };

        fetchData().then(r => console.log('r', r));
    }, [projectLimit]);

    const smallProjects = projectData.slice(0, 3).map(project => ({...project, large: false}));
    const largeProject = projectData.slice(3, 4).map(project => ({...project, large: true}));

    // const largeProjectId = 1772;
    // const smallProjects = projectData.slice(0, 3).map(project => ({...project, large: false}));
    // const largeProject = projectData.find(project => project.id === largeProjectId);
    // const largeProjectWithFlag = largeProject ? { ...largeProject, large: true } : null;


    return (
        <Box className={classes.boxWrapper}>
            <Box className={classes.smallCardBoxWrapper}>
                {smallProjects.map((project) => (
                    <ProjectCard project={project} projectMediaData={projectMediaData}/>
                ))}
            </Box>
            <Box className={classes.largeCardBoxWrapper}>
                {largeProject.length > 0 && (
                    <ProjectCard project={largeProject[0]} projectMediaData={projectMediaData}/>
                )}
            </Box>
        </Box>
    );
};

export default Projects;


