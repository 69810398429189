import React from 'react';
import PostsCard from '../../../UI/ActualPostCard';
import H1 from "../../../UI/H1";
import SectionWrapper from "../../../UI/SectionWrapper";
import { makeStyles } from "@material-ui/core/styles";
import StyledButton from "../../../UI/StyledButton";
import { Grid } from "@material-ui/core";
import UnderLine from "../../../UI/LineUnderTitle";

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

const ActualPostsBlock = () => {
    const classes = useStyles();

    return (
        <SectionWrapper id="main-actual-posts" paddingTop="30px">
            <H1 text="Aktualności"/>
            <UnderLine/>
            <PostsCard postLimit={3}/>
            <Grid container spacing={3} className={classes.buttonContainer}>
                <Grid item xs={12} sm={6} md={4}>
                    <StyledButton text="Zobacz więcej" href="/aktualnosci" width="100%" to="/aktualnosci"/>
                </Grid>
            </Grid>
        </SectionWrapper>
    );
};

export default ActualPostsBlock;
