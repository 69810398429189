// import React, {useEffect, useState} from 'react';
// import axios from 'axios';
// import {Box, Grid, Typography} from "@material-ui/core";
// import {makeStyles} from "@material-ui/core/styles";
// import H4 from "../H4";
// import {Link} from "react-router-dom";
// import {useTheme} from "../../../theme/themeContext";
// import {themes} from "../../../theme/themeContext/themes";
//
// const useStyles = makeStyles((theme) => ({
//     root: {
//         backgroundColor: ({backgroundColor}) => backgroundColor,
//         color: ({textColor}) => textColor,
//         display: 'flex',
//         flexDirection: 'column',
//         minHeight: '100%',
//         cursor: 'pointer',
//         transition: "all 0.5s ease-out",
//         '&:hover': {
//             transition: "all 0.5s ease-out",
//             transform: 'scale(1.02)',
//             '& $image': {
//                 transition: "all 0.5s ease-out",
//                 transform: 'scale(1.05)',
//             },
//         },
//         '&:hover $mainTitle': {
//             transition: "all 0.3s ease-out",
//             color: ({postsHoverTextColor}) => postsHoverTextColor,
//         },
//     },
//     textContainer: {
//         flex: 1,
//         padding: '10px',
//         border: '1px solid',
//         borderColor: ({borderColor}) => borderColor,
//     },
//     image: {
//         transition: "all 0.3s ease-out",
//         width: '100%',
//         height: '55vh',
//         objectFit: 'cover',
//         objectPosition: 'center',
//         [theme.breakpoints.down('sm')]: {
//             height: '40vh',
//         }
//     },
//     imageContainer: {
//         display: 'flex',
//         flex: '0 0 auto',
//         overflow: 'hidden',
//     },
//     date: {
//         fontFamily: 'Helvetica-Regular',
//         fontSize: '20px',
//         fontWeight: '400',
//         lineHeight: '23px',
//         letterSpacing: '0em',
//         opacity: '0.6',
//         [theme.breakpoints.down('sm')]: {
//             fontSize: '16px',
//             lineHeight: '18px',
//         }
//     },
//     line: {
//         width: '70%',
//         backgroundColor: ({lineBackgroundColor}) => lineBackgroundColor,
//         opacity: '0.6',
//         marginBottom: '12px',
//         height: '1px',
//         [theme.breakpoints.down('sm')]: {
//             width: '80%',
//         }
//     },
//     //TODO: refactor
//     cardWrapper: {
//         marginBottom: '6px',
//     },
//     linkWrapper: {
//         textDecoration: 'none',
//         color: 'inherit', // Унаследовать цвет из родительского элемента
//     },
// }));
//
// const PostCard = ({post, mediaData}) => {
//     const {theme} = useTheme();
//     const classes = useStyles(themes[theme]);
//     const featuredMediaId = post.featured_media;
//     const media = featuredMediaId ? mediaData[featuredMediaId] : null;
//
//     return (
//         <Link to={`/aktualnosci/${post.slug}`} className={classes.linkWrapper}>
//             <Box className={classes.root}>
//                 {media && (
//                     <Box className={classes.imageContainer}>
//                         <img src={media.source_url} alt={post.title.rendered} className={classes.image} loading="lazy"/>
//                     </Box>
//                 )}
//                 <Box className={classes.textContainer}>
//                     <H4 className={classes.mainTitle}>{post.title.rendered}</H4>
//                     <div className={classes.line}/>
//                     <Typography variant="body2" className={classes.date}>
//                         {new Date(post?.date).toLocaleDateString('pl-PL', { month: 'long', day: 'numeric', year: 'numeric' })}
//                     </Typography>
//                 </Box>
//             </Box>
//         </Link>
//     );
// };
//
// const PostsCard = ({postLimit}) => {
//     const [postData, setPostData] = useState([]);
//     const [mediaData, setMediaData] = useState({});
//
//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await axios.get(`https://fundacjanadrzeka.com/api/wp-json/wp/v2/posts?per_page=${postLimit}`);
//                 setPostData(response.data);
//
//                 const mediaIds = response.data.map((post) => post.featured_media);
//                 const mediaPromises = mediaIds.map((mediaId) =>
//                     axios.get(`https://fundacjanadrzeka.com/api/wp-json/wp/v2/media/${mediaId}`)
//                 );
//                 const mediaResults = await Promise.all(mediaPromises);
//
//                 const mediaDataObject = mediaResults.reduce((acc, media) => {
//                     acc[media.data.id] = media.data;
//                     return acc;
//                 }, {});
//                 setMediaData(mediaDataObject);
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };
//
//         fetchData().then(r => console.log(r));
//     }, [postLimit]);
//
//     const classes = useStyles();
//
//     return (
//         <Grid container spacing={3} className={classes.cardWrapper}>
//             {postData.map((post) => (
//                 <Grid item xs={12} sm={6} md={4} key={post.id}>
//                     <PostCard post={post} mediaData={mediaData}/>
//                 </Grid>
//             ))}
//         </Grid>
//     );
// };
//
// export default PostsCard;


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PostCard from "./PostCard";
import { useTheme } from "../../../theme/themeContext";
import { themes } from "../../../theme/themeContext/themes";

const useStyles = makeStyles(() => ({
    cardWrapper: {
        marginBottom: '6px',
    },
}));

const PostsCard = ({ postLimit }) => {
    const [postData, setPostData] = useState([]);
    const [mediaData, setMediaData] = useState({});
    const {theme} = useTheme();
    const classes = useStyles(themes[theme]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://fundacjanadrzeka.com/api/wp-json/wp/v2/posts?per_page=${postLimit}`);
                setPostData(response.data);

                const mediaIds = response.data.map((post) => post.featured_media);
                const mediaPromises = mediaIds.map((mediaId) =>
                    axios.get(`https://fundacjanadrzeka.com/api/wp-json/wp/v2/media/${mediaId}`)
                );
                const mediaResults = await Promise.all(mediaPromises);

                const mediaDataObject = mediaResults.reduce((acc, media) => {
                    acc[media.data.id] = media.data;
                    return acc;
                }, {});
                setMediaData(mediaDataObject);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData().then(r => console.log(r));
    }, [postLimit]);

    return (
        <Grid container spacing={3} className={classes.cardWrapper}>
            {postData.map((post) => (
                <Grid item xs={12} sm={6} md={4} key={post.id}>
                    <PostCard post={post} mediaData={mediaData} />
                </Grid>
            ))}
        </Grid>
    );
};

export default PostsCard;