import React from 'react';
import StartBanner from "../../../UI/MainBanner";

const MainBannerComponent = () => {
    return (
        <>
            <StartBanner
                text="Na granicy turystyki i kultury bez granic państwowych"
                projectLimit={1}
            />
        </>
    );
};

export default MainBannerComponent;
