import React, {useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import './styles.css';
import {Box} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import {Autoplay} from "swiper/modules";
import {useTheme} from "../../../theme/themeContext";
import {themes} from "../../../theme/themeContext/themes";

const useStyles = makeStyles((theme) => ({
    root: {
        //display: 'none',
        [theme.breakpoints.down('sm')]: {
            //display: 'block',
            //height: '350px',
        },
    },
    swiperContainer: {
        transitionTimingFunction: 'unset',
        position: 'relative',
        width: '100%',
        height: '100%',
        zIndex: '1',
        display: 'inline-flex',
        transitionProperty: 'transform',
        boxSizing: 'content-box',
    },
    progressBar: {
        width: '100%',
        height: '8px',
        backgroundColor: 'transparent',
        borderRadius: '0',
        border: '1px solid',
        borderColor: ({defaultSliderBackgroundColor}) => defaultSliderBackgroundColor,
        marginTop: '32px',
    },
    swiperProgressBar: {
        height: '7px',
        backgroundColor: ({defaultSliderBackgroundColor}) => defaultSliderBackgroundColor,
        transition: 'width 0.3s ease-in-out',
        borderRadius: '0',
    }

}));


const Slider = ({projects, slideComponent}) => {
    const {theme} = useTheme();
    const classes = useStyles(themes[theme]);

    const [progress, setProgress] = useState(0);

    const handleSlideChange = (swiper) => {
        const currentSlideIndex = swiper.activeIndex + 4;
        const totalSlides = swiper.slides.length;
        let progress;

        // Проверяем текущую ширину экрана и выбираем соответствующий подсчёт прогресса
        if (window.innerWidth >= 1024) {
            // Большие экраны: прогресс равен текущему слайду деленному на общее количество слайдов
            progress = currentSlideIndex / totalSlides;
        } else {
            // Мобильные экраны: прогресс равен текущему слайду деленному на общее количество слайдов минус 2
            // для компенсации смещения начала прогресс-полосы
            progress = (currentSlideIndex - 3) / totalSlides;
        }

        const clampedProgress = Math.min(Math.max(progress, 0), 1); // Ограничиваем значение прогресса от 0 до 1
        setProgress(clampedProgress);
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.swiperContainer}>
                <Swiper
                    modules={[Autoplay]}
                    spaceBetween={20}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        500: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        600: {
                            slidesPerView: 3,
                            spaceBetween: 10,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 10,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                        1200: {
                            slidesPerView: 5,
                            spaceBetween: 20,
                        },
                    }}
                    slider="true"
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    watchSlidesProgress
                    onSlideChange={handleSlideChange}
                >
                    {projects.map((project) => (
                        <SwiperSlide key={project.id} className="project-card">
                            {slideComponent({project})}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Box>
            <Box style={{display: 'flex', justifyContent: 'center'}}>
                <Box className={classes.progressBar}>
                    <div className={classes.swiperProgressBar} style={{width: `${progress * 100}%`}}/>
                </Box>
            </Box>
        </Box>
    );
};

export default Slider;